import React from 'react';
import './App.css';
import sky from './sky4.jpg';

function App() {
  return (
    <div className="App" style={{ backgroundImage: `url('${sky}'` }}>
      hi
    </div>
  );
}

export default App;
